import './App.css';
import HomePhoto from "./components/HomePhoto";
import Biografia from "./components/Biografia";
import Menu from "./components/Menu";
import Contatti from "./components/Contatti";
import Curriculum from "./components/Curriculum";
import Pubblicazioni from "./components/Pubblicazioni";


function App() {
    return (
        <>
            <Menu/>
            <HomePhoto/>
            <Biografia/>
            <Curriculum/>
            <Pubblicazioni/>
            <Contatti/>

        </>
    );
}

export default App;