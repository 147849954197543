import curriculum from '../assets/curriculum-aquino-sabino.pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
import curriculumPhoto from '../assets/curriculum-vitae.avif'

function Curriculum() {
    return (
        <div className="bg-gray-100 py-5 md:py-10 md:py-10 flex flex-col gap-2" id="curriculum">
            <div className="bg-white">
                <div
                    className="container mx-auto  flex flex-col md:flex-row gap-5 max-w-6xl justify-between items-center bg-white p-5">

                    <div className="w-full h-40 md:h-auto md:w-80 flex flex-row">
                        <img src={curriculumPhoto}
                             alt="curriculum"
                             className="rounded object-cover"/>
                    </div>

                    <div className="flex-1">
                    <div className=" text-4xl text-center md:text-left">
                        Curriculum Vitae
                    </div>
                        <div className="my-5 text-center md:text-left text-2xl uppercase">
                            <a href={curriculum} target="_blank"
                               rel="noreferrer"
                               className="bg-green-800 p-2 rounded-lg text-white"><FontAwesomeIcon icon={faDownload} /> Scarica</a>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Curriculum;