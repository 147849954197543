import aquino from '../assets/aquino.jpg';
import {motion} from "framer-motion"

function HomePhoto() {

    const containerPhoto = {
        hidden: {
            x: 300,
            opacity: 0,
        },
        show: {
            x: 0,
            opacity: 1,
            transition: {
                opacity: {
                    duration: 3,
                },
                x: {
                    duration: 1,
                },
            }
        },

    }

    const containerText1 = {
        hidden: {
            x: -300,
            opacity: 0,
        },
        show: {
            x: 0,
            opacity: 1,
            transition: {
                delay: 1,
                opacity: {
                    duration: 1,
                },
                x: {
                    duration: 1,
                },
            }
        },

    }

    const containerText2 = {
        hidden: {
            x: -300,
            opacity: 0,
        },
        show: {
            x: 0,
            opacity: 1,
            transition: {
                delay: 1.5,
                opacity: {
                    duration: 1,
                },
                x: {
                    duration: 1,
                },
            }
        },

    }

    return (
        <div className="h-screen w-full flex flex-row justify-center items-center relative">
            <motion.img
                className="h-screen w-full object-cover"
                variants={containerPhoto}
                initial="hidden"
                animate="show"
                src={aquino}
                alt="foto-principale"
            />

            <motion.div
                variants={containerText1}
                initial="hidden"
                animate="show"
                className="absolute
                            border-r-[20px]
                            border-green-700
                            shadow-lg
                            shadow-gray-500
                            text-lg
                            md:text-2xl
                            lg:text-4xl
                            bottom-[140px]
                            left-[10px]
                            md:bottom-[140px]
                            md:left-[40px]
                            lg:bottom-[200px]
                            lg:left-[100px]
                            bg-white
                            px-2
                            py-1
                            md:px-5
                            md:py-2">
                Sabino Aquino
            </motion.div>
            <motion.div
                variants={containerText2}
                initial="hidden"
                animate="show"
                className="absolute
                            border-r-[20px]
                            border-green-700
                            shadow-lg
                            shadow-gray-500
                            text-lg
                            md:text-2xl
                            bottom-[80px]
                            left-[10px]
                            md:bottom-[80px]
                            md:left-[40px]
                            lg:text-4xl
                            lg:bottom-[130px]
                            lg:left-[100px]
                            bg-white
                            px-2
                            py-1
                            md:px-5
                            md:py-2">
                Geologo
            </motion.div>

        </div>
    );
}

export default HomePhoto;