import {useState} from "react";


function MenuStart(){
    const [menuOpen, setMenuOpen] = useState(false);
    const handleClickScroll = name => {
        const element = document.getElementById(name);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({behavior: 'smooth'});
        }
    };

    // const animation = {
    //     offscreen: {
    //         y: -200,
    //         opacity: 0,
    //     },
    //     onscreen: {
    //         y: 0,
    //         opacity: 1,
    //         transition: {
    //             type: "spring",
    //             duration: 5
    //         }
    //     }
    // };

    return (
        <div className="z-10 fixed top-0 w-full bg-gray-100 md:opacity-50" >
            <div className="md:hidden w-full text-right">
                <button
                    onClick={() => setMenuOpen(!menuOpen)}
                    className="text-black focus:outline-none focus:ring-2 focus:ring-white py-4 px-8"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </button>
            </div>
            <div
                className="
                w-full
                md:w-3/4
                mx-auto
                p-5
                hidden
                md:flex
                flex-row
                items-center
                justify-around
                font-bold
                uppercase
                ">
                <button onClick={()=>handleClickScroll('biografia')}>Biografia</button>
                <button onClick={()=>handleClickScroll('curriculum')}>Curriculum</button>
                <button onClick={()=>handleClickScroll('pubblicazioini')}>Pubblicazioini</button>
                <button onClick={()=>handleClickScroll('contatti')}>Contatti</button>
            </div>

            {
                menuOpen && (
                    <div
                        className="
                        bg-white
                        mx-auto
                        p-5
                        flex-col
                        items-center
                        justify-end
                        gap-4
                        flex-1
                        flex
                        md:hidden
                        ">
                        <button onClick={() => handleClickScroll('biografia')}>Biografia</button>
                        <button onClick={() => handleClickScroll('curriculum')}>Curriculum</button>
                        <button onClick={() => handleClickScroll('pubblicazioini')}>Pubblicazioini</button>
                        <button onClick={() => handleClickScroll('contatti')}>Contatti</button>
                    </div>
                )
            }
        </div>
    );
}

export default MenuStart;