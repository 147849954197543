const rows = [
  {
    "path": "Pubblicazioni Sabino Aquino/1991-Galasso et al., Studio di un bacino endoreico-Piana del Dragone.pdf",
    "name": "1991-Galasso et al., Studio di un bacino endoreico-Piana del Dragone.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1993-Celico et al., Schema idrogeologico Terminio-Tuoro.pdf",
    "name": "1993-Celico et al., Schema idrogeologico Terminio-Tuoro.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1993-Piscopo et al., Schema idrogeologico dei Monti Accellica.pdf",
    "name": "1993-Piscopo et al., Schema idrogeologico dei Monti Accellica.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1994-Bolognini et al., Produttività pozzi in acquiferi carbonatici molto fratturati, Alburni.pdf",
    "name": "1994-Bolognini et al., Produttività pozzi in acquiferi carbonatici molto fratturati, Alburni.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1994-Calcaterra et al., Analisi idrodinamica del massiccio del Terminio-Tuoro.pdf",
    "name": "1994-Calcaterra et al., Analisi idrodinamica del massiccio del Terminio-Tuoro.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1994-Celico et al., Caratteristiche geochimiche delle acque sotterranee dell'area del Somma-Veusvio.pdf",
    "name": "1994-Celico et al., Caratteristiche geochimiche delle acque sotterranee dell'area del Somma-Veusvio.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1994-Celico et al., Metodo DRASTIC MC nella prevenzione dell'inquinamento.pdf",
    "name": "1994-Celico et al., Metodo DRASTIC MC nella prevenzione dell'inquinamento.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1994-Celico et alii, Idrogeologia idrogeochimica Alburni.pdf",
    "name": "1994-Celico et alii, Idrogeologia idrogeochimica Alburni.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1994-Celico et alii, Vulnerabilità inquinamento Terminio-Tuoro.pdf",
    "name": "1994-Celico et alii, Vulnerabilità inquinamento Terminio-Tuoro.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1996-Celico et al., Modifiche antropiche e vulnerabilità all'inquinamento del Taburno.pdf",
    "name": "1996-Celico et al., Modifiche antropiche e vulnerabilità all'inquinamento del Taburno.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1996-Celico et al., Propagazione degli inquinanti idroveicolati in falda.pdf",
    "name": "1996-Celico et al., Propagazione degli inquinanti idroveicolati in falda.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1996-Celico et al., Studio delle correlazioni esistenti tra morfologia della piezometrica e propagazione inquinanti.pdf",
    "name": "1996-Celico et al., Studio delle correlazioni esistenti tra morfologia della piezometrica e propagazione inquinanti.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1996-Celico et al., Vulnerabilità del Monte Camposauro e della Piana di Solopaca.pdf",
    "name": "1996-Celico et al., Vulnerabilità del Monte Camposauro e della Piana di Solopaca.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1997-Aquino, Osservazioni idrogeologiche e degrado del comprensorio a Sud-ovest di Avellino.pdf",
    "name": "1997-Aquino, Osservazioni idrogeologiche e degrado del comprensorio a Sud-ovest di Avellino.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1998-Guadagno et al., Caratterizzazione idrogeologica dell'acquifero del Calore.pdf",
    "name": "1998-Guadagno et al., Caratterizzazione idrogeologica dell'acquifero del Calore.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1998-Santo et al., Problemi di geologia ambientale nei polje appenninici-Piana di Forino.pdf",
    "name": "1998-Santo et al., Problemi di geologia ambientale nei polje appenninici-Piana di Forino.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1999-Aquino, Rete di monitoraggio per la valutazione e la salvaguardia delle risorse del Terminio-Tuoro.pdf",
    "name": "1999-Aquino, Rete di monitoraggio per la valutazione e la salvaguardia delle risorse del Terminio-Tuoro.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1999-Celico et al., Studio idrogeologico della Comunità Montana del Partenio.pdf",
    "name": "1999-Celico et al., Studio idrogeologico della Comunità Montana del Partenio.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1999-Piscopo et alii, Conoscenze idrogeologiche uso sostenibile Calore.pdf",
    "name": "1999-Piscopo et alii, Conoscenze idrogeologiche uso sostenibile Calore.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2000-Aquino, Aspetti geologici e geomorfologici del Massiccio del Monte Camposauro.pdf",
    "name": "2000-Aquino, Aspetti geologici e geomorfologici del Massiccio del Monte Camposauro.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2000-Aquino, Aspetti geologici, idrogeologici e stato di degrado del Rio Fenestrelle.pdf",
    "name": "2000-Aquino, Aspetti geologici, idrogeologici e stato di degrado del Rio Fenestrelle.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2000-Aquino, Idrogeologia del massiccio carbonatico del Monte Camposauro.pdf",
    "name": "2000-Aquino, Idrogeologia del massiccio carbonatico del Monte Camposauro.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2000-Aquino, Riflessioni sul degrado ambientale dei Fiume Calore.pdf",
    "name": "2000-Aquino, Riflessioni sul degrado ambientale dei Fiume Calore.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2000-Aquino, Una rete di monitoraggio del dato idrogeologico e meteo climatico del Terminio.pdf",
    "name": "2000-Aquino, Una rete di monitoraggio del dato idrogeologico e meteo climatico del Terminio.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2000-Celico et al., Instabilità coltri piroclastiche Pizzo d'Alvano.pdf",
    "name": "2000-Celico et al., Instabilità coltri piroclastiche Pizzo d'Alvano.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2001-Aquino et al., Idrogeologia del massiccio carbonatico del Terminio-Tuoro (Campania).pdf",
    "name": "2001-Aquino et al., Idrogeologia del massiccio carbonatico del Terminio-Tuoro (Campania).pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2001-Aquino, Monitoraggio in telemisura l'esempio del Terminio.pdf",
    "name": "2001-Aquino, Monitoraggio in telemisura l'esempio del Terminio.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2001-Aquino, Protezione e gestione delle risorse del Terminio.pdf",
    "name": "2001-Aquino, Protezione e gestione delle risorse del Terminio.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2001-Aquino, Protezione e gestione delle risorse idriche del Terminio attraverso il monitoraggio in telemisura.pdf",
    "name": "2001-Aquino, Protezione e gestione delle risorse idriche del Terminio attraverso il monitoraggio in telemisura.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2001-Esposito et al., Idrogeologia e potenzialità idrica Terminio Tuoro.pdf",
    "name": "2001-Esposito et al., Idrogeologia e potenzialità idrica Terminio Tuoro.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2001-Fiorillo et al., December 1999 Cervinara landslides.pdf",
    "name": "2001-Fiorillo et al., December 1999 Cervinara landslides.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2001-Fiorillo et al., Il ruolo dell'acqua nell'innesco dei fenomeni d'instabilità superficiali.pdf",
    "name": "2001-Fiorillo et al., Il ruolo dell'acqua nell'innesco dei fenomeni d'instabilità superficiali.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2001-Piscopo et alii, Presenza di falde sospese Monte Accellica.pdf",
    "name": "2001-Piscopo et alii, Presenza di falde sospese Monte Accellica.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2002-Aquino et al., La Piana del Dragone un lembo di territorio da proteggere.pdf",
    "name": "2002-Aquino et al., La Piana del Dragone un lembo di territorio da proteggere.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2002-Aquino, Fiume Sabato degrado ambientale e risanamento.pdf",
    "name": "2002-Aquino, Fiume Sabato degrado ambientale e risanamento.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2002-Aquino, La Piana del Dragone, La Chiana re lo Traone.pdf",
    "name": "2002-Aquino, La Piana del Dragone, La Chiana re lo Traone.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2003-Aquino, Effetti antropici e naturali sul degrado del Sabato.pdf",
    "name": "2003-Aquino, Effetti antropici e naturali sul degrado del Sabato.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2003-Aquino, Il fiume Sabato risorsa da normalizzare.pdf",
    "name": "2003-Aquino, Il fiume Sabato risorsa da normalizzare.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2003-Giulivo et al., Le grotte del caliendo un paradiso da riscoprire.pdf",
    "name": "2003-Giulivo et al., Le grotte del caliendo un paradiso da riscoprire.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2004-Aquino, Il problema delle acque in Irpinia.pdf",
    "name": "2004-Aquino, Il problema delle acque in Irpinia.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2005-Aquino et al., Analisi geologico-ambientale Fenestrelle.pdf",
    "name": "2005-Aquino et al., Analisi geologico-ambientale Fenestrelle.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2005-Aquino, Effetti antropici sul degrado ambientale del reticolo idrografico del fiume Picentino.pdf",
    "name": "2005-Aquino, Effetti antropici sul degrado ambientale del reticolo idrografico del fiume Picentino.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2005-Aquino, Parco dei Picentini sviluppo sostenibile.pdf",
    "name": "2005-Aquino, Parco dei Picentini sviluppo sostenibile.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2005-Aquino, Pianificazione e gestione del Parco regionale dei Picentini.pdf",
    "name": "2005-Aquino, Pianificazione e gestione del Parco regionale dei Picentini.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2005-Esposito et al., Aspetti idrogeologici ed analisi della vulnerabilità dell'acquifero alluvionale della Piana di Benevento.pdf",
    "name": "2005-Esposito et al., Aspetti idrogeologici ed analisi della vulnerabilità dell'acquifero alluvionale della Piana di Benevento.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2005-Esposito et al., Idrogeologia del Sannio.pdf",
    "name": "2005-Esposito et al., Idrogeologia del Sannio.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2006-Aquino, La questione delle acque in Irpinia.pdf",
    "name": "2006-Aquino, La questione delle acque in Irpinia.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2006-Aquino, Le acque del Monte Terminio Tuoro monitorate in telemisura.pdf",
    "name": "2006-Aquino, Le acque del Monte Terminio Tuoro monitorate in telemisura.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2006-Aquino, Le nostre risorse acqua e sorgenti.pdf",
    "name": "2006-Aquino, Le nostre risorse acqua e sorgenti.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2006-Aquino, Parco dei Picentini programmazione 2007-2013.pdf",
    "name": "2006-Aquino, Parco dei Picentini programmazione 2007-2013.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2006-Aquino, Protezione e gestione delle risorse idriche Terminio-Tuoro.pdf",
    "name": "2006-Aquino, Protezione e gestione delle risorse idriche Terminio-Tuoro.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2006-Aquino, Una diga pe salvare il Fiume Calore.pdf",
    "name": "2006-Aquino, Una diga pe salvare il Fiume Calore.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2007-Aquino et al., Analisi qualità ambientale bacini endoreici Piana del Dragone.pdf",
    "name": "2007-Aquino et al., Analisi qualità ambientale bacini endoreici Piana del Dragone.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2007-Aquino, Il ruolo del presidente di un parco nella conservazione della natura.pdf",
    "name": "2007-Aquino, Il ruolo del presidente di un parco nella conservazione della natura.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2007-Aquino, Pianificazione, rete ecologica e conservazione natura Picentini.pdf",
    "name": "2007-Aquino, Pianificazione, rete ecologica e conservazione natura Picentini.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2007-Aquino, Tutela delle acque nello scenario del nuovo codice dek'ambiente.pdf",
    "name": "2007-Aquino, Tutela delle acque nello scenario del nuovo codice dek'ambiente.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2007-Giulivo et al., La Grotta di Caliendo.pdf",
    "name": "2007-Giulivo et al., La Grotta di Caliendo.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2008-Allocca et al., Le acque sotterranee dell'Irpinia (Italia meridionale).pdf",
    "name": "2008-Allocca et al., Le acque sotterranee dell'Irpinia (Italia meridionale).pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2008-Aquino et al., Analisi geologico-ambientale Rio Fenestrelle.pdf",
    "name": "2008-Aquino et al., Analisi geologico-ambientale Rio Fenestrelle.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2008-Aquino et al., Assetto idrogeologico del Monte Cervialto e del Sele.pdf",
    "name": "2008-Aquino et al., Assetto idrogeologico del Monte Cervialto e del Sele.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2008-Aquino et al., Geologia e produzione vinicola contributo per il terroir della Provincia di Avellino.pdf",
    "name": "2008-Aquino et al., Geologia e produzione vinicola contributo per il terroir della Provincia di Avellino.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2008-Aquino et al., Geologia, climatologia, suoli e produzione vinicola.pdf",
    "name": "2008-Aquino et al., Geologia, climatologia, suoli e produzione vinicola.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2008-Aquino et al., Protezione da rischi di inquinamento delle risorse idriche.pdf",
    "name": "2008-Aquino et al., Protezione da rischi di inquinamento delle risorse idriche.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2008-Aquino, Stop alla Pavoncelli bis.pdf",
    "name": "2008-Aquino, Stop alla Pavoncelli bis.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2008-Esposito et al., Sentieri intelligenti Parco Regionale Picentini.pdf",
    "name": "2008-Esposito et al., Sentieri intelligenti Parco Regionale Picentini.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2009-Aquino et al., Geositi ed enositi itinerari patrimonio geologico e Irpinia.pdf",
    "name": "2009-Aquino et al., Geositi ed enositi itinerari patrimonio geologico e Irpinia.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2009-Naddeo et al., WDF implementation.pdf",
    "name": "2009-Naddeo et al., WDF implementation.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2010-Aquino et al., Rapporti tra geologia climatologia e pedologia per Taurasi Greco di Tufo e Fiano di Avellino.pdf",
    "name": "2010-Aquino et al., Rapporti tra geologia climatologia e pedologia per Taurasi Greco di Tufo e Fiano di Avellino.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2015-Aquino et al., Irpinia terra d'acqua.pdf",
    "name": "2015-Aquino et al., Irpinia terra d'acqua.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2016-Aquino et al., Gioiello dei Picentini Grotte del Caliendo.pdf",
    "name": "2016-Aquino et al., Gioiello dei Picentini Grotte del Caliendo.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2017-Aquino et al., Idrocarburi ed inquinamento in Irpinia-Sannio.pdf",
    "name": "2017-Aquino et al., Idrocarburi ed inquinamento in Irpinia-Sannio.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2019-Aquino et al., Le acque minerali e termali in Irpinia.pdf",
    "name": "2019-Aquino et al., Le acque minerali e termali in Irpinia.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2020-Aquino et al., Idrogeologia e potenzialità idrica Monti di Solofra.pdf",
    "name": "2020-Aquino et al., Idrogeologia e potenzialità idrica Monti di Solofra.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2021-Aquino et al., Analisi idrogeologica e ambientale Piana del Dragone.pdf",
    "name": "2021-Aquino et al., Analisi idrogeologica e ambientale Piana del Dragone.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2021-Aquino et al., Parco fluviale città di Avellino analisi idrogeologica e criticità ambientale.pdf",
    "name": "2021-Aquino et al., Parco fluviale città di Avellino analisi idrogeologica e criticità ambientale.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2021-Aquino et alii, Water potentiality and qualiti decay of Picentini.pdf",
    "name": "2021-Aquino et alii, Water potentiality and qualiti decay of Picentini.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2021-Trocciola et al., Analisi quali-quantitativa degli acquiferi sotterranei del Parco Regionale dei Picentini.pdf",
    "name": "2021-Trocciola et al., Analisi quali-quantitativa degli acquiferi sotterranei del Parco Regionale dei Picentini.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2022-Aquino et al. Interventi di sistemazione ambientale Piana di Forino-con cop. riv.pdf",
    "name": "2022-Aquino et al. Interventi di sistemazione ambientale Piana di Forino-con cop. riv.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2022-Aquino et al., Monitoraggio ventennale quali-quantiativo Terminio-Tuoro-con cop. riv.pdf",
    "name": "2022-Aquino et al., Monitoraggio ventennale quali-quantiativo Terminio-Tuoro-con cop. riv.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2022-Aquino, La valorizzazione dei parchi naturali e dei geositi d’Irpinia per lo sviluppo turistico del territorio - Orticalab.it.pdf",
    "name": "2022-Aquino, La valorizzazione dei parchi naturali e dei geositi d’Irpinia per lo sviluppo turistico del territorio - Orticalab.it.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Aquino et al., 2021-Analisi idrogeologica e ambientale Piana del Dragone.pdf",
    "name": "Aquino et al., 2021-Analisi idrogeologica e ambientale Piana del Dragone.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1994-Celico et al., Vulnerabilità all'inquinamento del massiccio carbonatico del Terminio-Tuoro/1994-Celico et al., Carta della vulnerabilità  Terminio-Tuoro.pdf",
    "name": "1994-Celico et al., Vulnerabilità all'inquinamento del massiccio carbonatico del Terminio-Tuoro-1994-Celico et al., Carta della vulnerabilità  Terminio-Tuoro.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1995-Nicotera et al., Una captazione sui generis per la sorgente Baiardo/1995-Nicotera et al., Captazione sui generis sorgente Baiardo.pdf",
    "name": "1995-Nicotera et al., Una captazione sui generis per la sorgente Baiardo-1995-Nicotera et al., Captazione sui generis sorgente Baiardo.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1995-Nicotera et al., Una captazione sui generis per la sorgente Baiardo/1995-Nicotera et al., Carta della galleria Baiardo.pdf",
    "name": "1995-Nicotera et al., Una captazione sui generis per la sorgente Baiardo-1995-Nicotera et al., Carta della galleria Baiardo.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1996-Celico et al., Vulnerabilità all'inquinamento acquiferi Monte Taburno e Camposauro/1996-Celico et al., Carta Vulnerabilità all'inquinamento Monte Camposauro.pdf",
    "name": "1996-Celico et al., Vulnerabilità all'inquinamento acquiferi Monte Taburno e Camposauro-1996-Celico et al., Carta Vulnerabilità all'inquinamento Monte Camposauro.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1996-Celico et al., Vulnerabilità all'inquinamento acquiferi Monte Taburno e Camposauro/1996-Celico et al., Carta Vulnerbilità all'inquinamento Monte Taburno.pdf",
    "name": "1996-Celico et al., Vulnerabilità all'inquinamento acquiferi Monte Taburno e Camposauro-1996-Celico et al., Carta Vulnerbilità all'inquinamento Monte Taburno.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1999-Celico et al., Idrodinamica sotterranea e vulnerabilità all'inquinamento degli acquiferi del Partenio/1999-Celico et al., Carta della vulnerabilità del Partenio.pdf",
    "name": "1999-Celico et al., Idrodinamica sotterranea e vulnerabilità all'inquinamento degli acquiferi del Partenio-1999-Celico et al., Carta della vulnerabilità del Partenio.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/1999-Celico et al., Idrodinamica sotterranea e vulnerabilità all'inquinamento degli acquiferi del Partenio/1999-Celico et al., Idrodinamica sotterranea e vulnerabilità.pdf",
    "name": "1999-Celico et al., Idrodinamica sotterranea e vulnerabilità all'inquinamento degli acquiferi del Partenio-1999-Celico et al., Idrodinamica sotterranea e vulnerabilità.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2003-Esposito et al., Aspetti idrogeologici del territorio Sannita/2003-Esposito et al., Aspetti idrogeologici del territorio Sannita.pdf",
    "name": "2003-Esposito et al., Aspetti idrogeologici del territorio Sannita-2003-Esposito et al., Aspetti idrogeologici del territorio Sannita.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2003-Esposito et al., Aspetti idrogeologici del territorio Sannita/2003-Esposito et al., Carta Idrogeologica della Provincia di Benevento.jpg",
    "name": "2003-Esposito et al., Aspetti idrogeologici del territorio Sannita-2003-Esposito et al., Carta Idrogeologica della Provincia di Benevento.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2003-Esposito et al., Aspetti idrogeologici del territorio Sannita/Carta Idrogeologica Prov Benevento.pdf",
    "name": "2003-Esposito et al., Aspetti idrogeologici del territorio Sannita-Carta Idrogeologica Prov Benevento.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2006-Aquino et al., Le Risorse Idriche della Provincia di Avellino/2006-Aquino et al., Carta Idrogeologica Provincia di Avellino.pdf",
    "name": "2006-Aquino et al., Le Risorse Idriche della Provincia di Avellino-2006-Aquino et al., Carta Idrogeologica Provincia di Avellino.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/2006-Aquino et al., Le Risorse Idriche della Provincia di Avellino/2006-Aquino et al., Le Risorse Idriche della Provincia di Avellino.pdf",
    "name": "2006-Aquino et al., Le Risorse Idriche della Provincia di Avellino-2006-Aquino et al., Le Risorse Idriche della Provincia di Avellino.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carta Idrogeologica dell'Italia Meridionale -Celico/2007-Allocca et al., Note illustrative carta idrogeologica Italia meridionale.pdf",
    "name": "Carte idrogeologiche-Carta Idrogeologica dell'Italia Meridionale -Celico-2007-Allocca et al., Note illustrative carta idrogeologica Italia meridionale.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carta Idrogeologica dell'Italia Meridionale -Celico/HydrogeologicalmapofSouthernItaly-IllustrativeNotesItalianversion-Celicoetal-2007.pdf",
    "name": "Carte idrogeologiche-Carta Idrogeologica dell'Italia Meridionale -Celico-HydrogeologicalmapofSouthernItaly-IllustrativeNotesItalianversion-Celicoetal-2007.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carta Idrogeologica dell'Italia Meridionale -Celico/Edizione 1999/1.jpg",
    "name": "Carte idrogeologiche-Carta Idrogeologica dell'Italia Meridionale -Celico-Edizione 1999-1.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carta Idrogeologica dell'Italia Meridionale -Celico/Edizione 1999/2.jpg",
    "name": "Carte idrogeologiche-Carta Idrogeologica dell'Italia Meridionale -Celico-Edizione 1999-2.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carta Idrogeologica dell'Italia Meridionale -Celico/Edizione 1999/3.jpg",
    "name": "Carte idrogeologiche-Carta Idrogeologica dell'Italia Meridionale -Celico-Edizione 1999-3.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carta Idrogeologica dell'Italia Meridionale -Celico/Edizione 1999/Carta Idrogeologica APAT.jpg",
    "name": "Carte idrogeologiche-Carta Idrogeologica dell'Italia Meridionale -Celico-Edizione 1999-Carta Idrogeologica APAT.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carta Idrogeologica dell'Italia Meridionale -Celico/Riedizione 2018/2009-Alloccaetal-Italian-LaCartaIdrogeologicadellItaliameridionale.pdf",
    "name": "Carte idrogeologiche-Carta Idrogeologica dell'Italia Meridionale -Celico-Riedizione 2018-2009-Alloccaetal-Italian-LaCartaIdrogeologicadellItaliameridionale.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carta Idrogeologica dell'Italia Meridionale -Celico/Riedizione 2018/Hydrogeology of continental southern Italy.pdf",
    "name": "Carte idrogeologiche-Carta Idrogeologica dell'Italia Meridionale -Celico-Riedizione 2018-Hydrogeology of continental southern Italy.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carta Idrogeologica dell'Italia Meridionale -Celico/Riedizione 2018/tjom_a_1454352_sm7151.pdf",
    "name": "Carte idrogeologiche-Carta Idrogeologica dell'Italia Meridionale -Celico-Riedizione 2018-tjom_a_1454352_sm7151.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carta Idrogeologica dell'Italia Meridionale -Celico/Riedizione 2018/tjom_a_1454352_sm7152.pdf",
    "name": "Carte idrogeologiche-Carta Idrogeologica dell'Italia Meridionale -Celico-Riedizione 2018-tjom_a_1454352_sm7152.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carta Idrogeologica dell'Italia Meridionale -Celico/Riedizione 2018/tjom_a_1454352_sm7153.pdf",
    "name": "Carte idrogeologiche-Carta Idrogeologica dell'Italia Meridionale -Celico-Riedizione 2018-tjom_a_1454352_sm7153.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte dei sentieri/Parco dei Monti Picentini/Libro sentieri.pdf",
    "name": "Carte idrogeologiche-Carte dei sentieri-Parco dei Monti Picentini-Libro sentieri.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte dei sentieri/Parco dei Monti Picentini/Tavola_light_A.pdf",
    "name": "Carte idrogeologiche-Carte dei sentieri-Parco dei Monti Picentini-Tavola_light_A.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte dei sentieri/Parco dei Monti Picentini/Tavola_light_B.pdf",
    "name": "Carte idrogeologiche-Carte dei sentieri-Parco dei Monti Picentini-Tavola_light_B.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte dei sentieri/Parco dei Monti Picentini/Tavola_light_C.pdf",
    "name": "Carte idrogeologiche-Carte dei sentieri-Parco dei Monti Picentini-Tavola_light_C.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte dei sentieri/Parco dei Monti Picentini/Tavola_light_D.pdf",
    "name": "Carte idrogeologiche-Carte dei sentieri-Parco dei Monti Picentini-Tavola_light_D.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte dei sentieri/Parco del Partenio/Carta Sentieri Partenio.pdf",
    "name": "Carte idrogeologiche-Carte dei sentieri-Parco del Partenio-Carta Sentieri Partenio.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte pubblicazioni/JPEG/2003-Esposito et al., Carta Idrogeologica della Provincia di Benevento.jpg",
    "name": "Carte idrogeologiche-Carte pubblicazioni-JPEG-2003-Esposito et al., Carta Idrogeologica della Provincia di Benevento.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte pubblicazioni/JPEG/AVELLINO COLORI BASE.jpg",
    "name": "Carte idrogeologiche-Carte pubblicazioni-JPEG-AVELLINO COLORI BASE.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte pubblicazioni/JPEG/Camposauro.jpg",
    "name": "Carte idrogeologiche-Carte pubblicazioni-JPEG-Camposauro.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte pubblicazioni/JPEG/partenio.JPG",
    "name": "Carte idrogeologiche-Carte pubblicazioni-JPEG-partenio.JPG"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte pubblicazioni/JPEG/Taburno.jpg",
    "name": "Carte idrogeologiche-Carte pubblicazioni-JPEG-Taburno.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte pubblicazioni/JPEG/Terminio.jpg",
    "name": "Carte idrogeologiche-Carte pubblicazioni-JPEG-Terminio.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte pubblicazioni/PDF/2006-Aquino et al., Carta Idrogeologica Provincia di Avellino.pdf",
    "name": "Carte idrogeologiche-Carte pubblicazioni-PDF-2006-Aquino et al., Carta Idrogeologica Provincia di Avellino.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Carte pubblicazioni/PDF/Carta Idrogeologica Prov Benevento.pdf",
    "name": "Carte idrogeologiche-Carte pubblicazioni-PDF-Carta Idrogeologica Prov Benevento.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Posters/Poster Budetta.pdf",
    "name": "Carte idrogeologiche-Posters-Poster Budetta.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Posters/poster vino II.jpg",
    "name": "Carte idrogeologiche-Posters-poster vino II.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Posters/poster vino.jpg",
    "name": "Carte idrogeologiche-Posters-poster vino.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Reticoli Fiumi/Files Jpg/Fiume Calore.jpg",
    "name": "Carte idrogeologiche-Reticoli Fiumi-Files Jpg-Fiume Calore.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Reticoli Fiumi/Files Jpg/Fiume Ofanto.jpg",
    "name": "Carte idrogeologiche-Reticoli Fiumi-Files Jpg-Fiume Ofanto.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Reticoli Fiumi/Files Jpg/Fiume Sabato.jpg",
    "name": "Carte idrogeologiche-Reticoli Fiumi-Files Jpg-Fiume Sabato.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Reticoli Fiumi/Files Jpg/Fiume Sele.jpg",
    "name": "Carte idrogeologiche-Reticoli Fiumi-Files Jpg-Fiume Sele.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Reticoli Fiumi/Files Jpg/Reticolo Calore.jpg",
    "name": "Carte idrogeologiche-Reticoli Fiumi-Files Jpg-Reticolo Calore.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Reticoli Fiumi/Files Jpg/Reticolo Picentino.jpg",
    "name": "Carte idrogeologiche-Reticoli Fiumi-Files Jpg-Reticolo Picentino.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Reticoli Fiumi/Files PDF/Reticolo Calore.pdf",
    "name": "Carte idrogeologiche-Reticoli Fiumi-Files PDF-Reticolo Calore.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Reticoli Fiumi/Files PDF/Reticolo Ofanto.pdf",
    "name": "Carte idrogeologiche-Reticoli Fiumi-Files PDF-Reticolo Ofanto.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Reticoli Fiumi/Files PDF/Reticolo Picentino.pdf",
    "name": "Carte idrogeologiche-Reticoli Fiumi-Files PDF-Reticolo Picentino.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Reticoli Fiumi/Files PDF/Reticolo Sabato.pdf",
    "name": "Carte idrogeologiche-Reticoli Fiumi-Files PDF-Reticolo Sabato.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/Reticoli Fiumi/Files PDF/Reticolo Sele.pdf",
    "name": "Carte idrogeologiche-Reticoli Fiumi-Files PDF-Reticolo Sele.pdf"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/schema terminio con stazioni/schema con stazioni.docx",
    "name": "Carte idrogeologiche-schema terminio con stazioni-schema con stazioni.docx"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/schema terminio con stazioni/schema terminio con stazioni.jpg",
    "name": "Carte idrogeologiche-schema terminio con stazioni-schema terminio con stazioni.jpg"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/schema terminio con stazioni/sensori.dotx",
    "name": "Carte idrogeologiche-schema terminio con stazioni-sensori.dotx"
  },
  {
    "path": "Pubblicazioni Sabino Aquino/Carte idrogeologiche/schema terminio con stazioni/sensoristica.jpg",
    "name": "Carte idrogeologiche-schema terminio con stazioni-sensoristica.jpg"
  }
]

export default rows;