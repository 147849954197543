import profilo from '../assets/profilo.jpg';
import {motion} from "framer-motion"


const animationProfilo = {
    offscreen: {

        opacity: 0,
    },
    onscreen: {
        opacity: 1,
        transition: {
            type: "spring",
            duration: 5
        }
    }
};

function Biografia() {
    return (

        <div className="bg-gray-100 py-5 lg:py-10 lg:py-10 flex flex-col gap-2" id="biografia">
            <div className="bg-white">
                <div
                    className="container mx-auto p-4 flex flex-col lg:flex-row gap-5 max-w-6xl justify-center items-center bg-white">

                    <div className="w-full lg:w-1/2">
                        <motion.div
                            variants={animationProfilo}
                            viewport={{once: false}}
                            initial="offscreen"
                            whileInView="onscreen"
                        >
                            <div className="w-[300px] lg:w-full mx-auto">
                                <div
                                    className="w-full block relative flex flex-row items-center justify-center overflow-hidden">
                                    <div className="">
                                        <img src={profilo} alt="aquino"/>
                                    </div>
                                    <div
                                        className="w-full h-full flex flex-row items-center justify-center absolute p-3">
                                        <div className="w-full h-full border border-white">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>

                    <div className="w-full lg:w-1/2 p-5">
                        <p className="py-5 font-bold text-2xl">SABINO AQUINO</p>
                        <ul className="list-disc list-biografia">
                            <li>Già Direttore del Processo Depurazione e Dirigente presso Società Alto Calore Servizi
                                (dal 1980 al 2015);
                            </li>
                            <li>Già Presidente Parco Regionale dei Monti Picentini (2005-2010);</li>
                            <li>Già Presidente Parco Regionale del Partenio (2010-2012);</li>
                            <li>Già Professore a contratto del corso di "Risorse idriche e protezione degli acquiferi"
                                presso il Dipartimento di Studi Geologici ed Ambientali dell'Università degli Studi del
                                Sannio (A.A. 2004/2005);
                            </li>
                            <li>Già Professore a contratto del corso di "Tecniche di monitoraggio del territorio e
                                dell'ambiente" presso il Dipartimento di Studi Geologici ed Ambientali dell'Università
                                degli Studi del Sannio (2005-2012);
                            </li>
                            <li>Già Professore a contratto di "Geologia idrologica e termale" nel master di II livello
                                in Idrologia e Medicina presso la Facoltà di Farmacia dell'Università degli Studi di
                                Salerno (A.A.2009-2010);
                            </li>
                            <li>Già Professore a contratto del corso di "Gestione e qualità ambientale: controllo della
                                qualità" nel master di II livello in Management Sanitario presso l'Università degli
                                Studi di Salerno (A.A. 2012/2013);
                            </li>
                            <li>Professore a contratto di Geologia Applicata – Corso di Laurea in Ingegneria Civile
                                (L-7) presso Università Telematica Pegaso dall’Anno Accademico 2017/2018 a tutt’oggi;
                            </li>
                            <li>Relatore di n. 230 Tesi di Laurea;</li>
                            <li>Autore e coautore di 107 pubblicazioni scientifiche;</li>
                            <li>Ha partecipato in qualità di relatore a n. 167 convegni Nazionali e Internazionali;</li>
                            <li>Autore di 181 articoli pubblicati su vari quotidiani e riviste;</li>
                            <li>Consulente per le Procure della Repubblica di Teramo e L’Aquila per l’inquinamento delle
                                acque del Gran Sasso d’Italia;
                            </li>
                            <li>Nel corso della sua attività professionale dall’anno 1977 a tutt’oggi ha eseguito oltre
                                1000 consulenze geologico-tecniche, idrogeologiche o ambientali di supporto a
                                progettazioni esecutive.
                            </li>
                        </ul>

                    </div>


                </div>
            </div>

        </div>
    );
}

export default Biografia;