import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome,faPhone,faEnvelope,faAt} from '@fortawesome/free-solid-svg-icons'
import contatti from '../assets/contatti.jpeg'

function Contatti(){

    return (
        <div className="bg-gray-100 py-5 md:py-10 md:py-10 flex flex-col gap-2" id="contatti">
            <div className="bg-white">
                <div
                    className="container mx-auto flex flex-col flex-col-reverse md:flex-row gap-5 max-w-6xl justify-between items-center bg-white p-5">



                    <div className="flex-1">
                        <div className="w-full lg:w-1/2">
                            <p className="py-2 uppercase"><FontAwesomeIcon icon={faHome} /> CONTRADA ARCHI, 9/C AVELLINO (AV) 83100</p>
                            <p className="py-2"><FontAwesomeIcon icon={faPhone} /> Telefono: 0825/26698</p>
                            <p className="py-2"><FontAwesomeIcon icon={faEnvelope} /> E-mail: aquino.ambiente@virgilio.it</p>
                            <p className="py-2"><FontAwesomeIcon icon={faAt} /> P.E.C.: sabino.aquino@pec.epap.it</p>
                        </div>
                    </div>
                    <div className="w-full h-40 md:h-auto md:w-80 flex flex-row">
                        <img src={contatti}
                             className="rounded object-cover w-full"
                             alt="contatti"
                        />
                    </div>

                </div>
            </div>

        </div>
    );


}
export default Contatti;