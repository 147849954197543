
import {useEffect, useState} from "react";
import MenuScrolled from "./MenuScrolled";
import MenuStart from "./MenuStart";

function Menu() {
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(function (){
        const handleScroll = event => {
            setScrollTop(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[])



    return (scrollTop>100 ? <MenuScrolled/> : <MenuStart/>);
}

export default Menu