import {motion} from "framer-motion";
import {useState} from "react";

function MenuScrolled() {

    const [menuOpen, setMenuOpen] = useState(false);

    const handleClickScroll = name => {
        setMenuOpen(false);
        const element = document.getElementById(name);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({behavior: 'smooth'});
        }
    };

    const animation = {
        offscreen: {
            y: -200,
            opacity: 0,
        },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                duration: 2
            }
        }
    };

    return (
        <motion.div
            variants={animation}
            initial="offscreen"
            className="z-10 fixed top-0 w-full flex flex-col bg-white shadow shadow-gray-100 gap-1"
            animate="onscreen"
        >
            <div className="w-full flex flex-row justify-between items-center gap-4">
                <div className="p-2 text-2xl font-bold uppercase">
                    Sabino Aquino
                </div>

                <div className="md:hidden">
                    <button
                        onClick={() => setMenuOpen(!menuOpen)}
                        className="text-black focus:outline-none focus:ring-2 focus:ring-white px-8"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                </div>

                <div
                    className="
                mx-auto
                p-5
                flex-row
                items-center
                justify-end
                gap-4
                flex-1
                hidden
                md:flex
                ">
                    <button onClick={() => handleClickScroll('biografia')}>Biografia</button>
                    <button onClick={() => handleClickScroll('curriculum')}>Curriculum</button>
                    <button onClick={() => handleClickScroll('pubblicazioini')}>Pubblicazioini</button>
                    <button onClick={() => handleClickScroll('contatti')}>Contatti</button>
                </div>
            </div>


            {
                menuOpen && (
                    <div
                        className="
                        mx-auto
                        p-5
                        flex-col
                        items-center
                        justify-end
                        gap-4
                        flex-1
                        flex
                        md:hidden
                        ">
                        <button onClick={() => handleClickScroll('biografia')}>Biografia</button>
                        <button onClick={() => handleClickScroll('curriculum')}>Curriculum</button>
                        <button onClick={() => handleClickScroll('pubblicazioini')}>Pubblicazioini</button>
                        <button onClick={() => handleClickScroll('contatti')}>Contatti</button>
                    </div>
                )
            }
        </motion.div>
    );
}

export default MenuScrolled;