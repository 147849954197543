import {useState} from 'react';
import rows from "../assets/listaPubblicazioni";

function Pubblicazioni() {


    const [pubblicazioni] = useState(rows);
    const [rowData,setRowData] = useState(rows);

    const handleRowData = (data)=>{
        let searchString = data.target.value;
        const searchWords = searchString.toLowerCase().split(' ');
        const filteredArray = pubblicazioni.filter(item => {
            const lowerCaseTargetString = item.name.toLowerCase();
            for (const word of searchWords) {
                if (!lowerCaseTargetString.includes(word)) {
                    return false; // Se una parola non è presente, restituisco false
                }
            }
            return true;
        });
        setRowData(filteredArray);
    }


    return (
        <>

            <div className="bg-gray-100 py-5 lg:py-10 gap-2" id="pubblicazioini">

                <div className="bg-white">
                    <div
                        className="container  mx-auto p-4 flex flex-col gap-5 max-w-6xl justify-center items-center bg-white">
                        <p className="font-bold uppercase">
                            Pubblicazioni
                        </p>
                        <input placeholder="Ricerca"
                               className="border border-gray-200 p-1 w-full rounded"
                                onKeyUp={handleRowData}
                        />
                        <div className="w-full h-64 overflow-auto">
                            <table className="w-full">

                                {
                                    rowData.map(row => {
                                        return (
                                            <tr className="odd:bg-gray-100 hover:bg-gray-200">
                                                <td className="p-1">{row.name}</td>
                                                <td className="px-4">
                                                    <a
                                                        href={"/"+row.path}
                                                        download
                                                    className="bg-amber-400 py-0.5 px-1 rounded text-white"
                                                    >
                                                        Scarica
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Pubblicazioni;